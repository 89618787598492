import styled from 'styled-components';

export const AuthenticationContainer = styled.div`
  display: flex;
  width: 900px;
  justify-content: space-between;
  margin: 30px auto;  
`

// .authentication-container {
//   display: flex;
//   width: 900px;
//   justify-content: space-between;
//   margin: 30px auto;
// }